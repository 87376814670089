import { Pipe, PipeTransform } from '@angular/core';

let emojiDictionary = [
    { patterns: [':)', ':-)', '=)'], unicode: 'ð' },
    { patterns: [':D', ':-D', '=D'], unicode: 'ð' },
    { patterns: [':(', ':-(', '=('], unicode: 'ð' },
    { patterns: [':|', ':-|', '=|'], unicode: 'ð' },
    { patterns: [':*', ':-*', '=*'], unicode: 'ð' },
    { patterns: ['T_T', 'T.T'], unicode: 'ð­' },
    { patterns: [':O', ':-O', '=O', ':o', ':-o', '=o'], unicode: 'ð®' },
    { patterns: [':P', ':-P', '=P', ':p', ':-p', '=p'], unicode: 'ð' },
    { patterns: ['>.<'], unicode: 'ð£' },
    { patterns: ['@.@'], unicode: 'ðµ' },
    { patterns: ['*.*'], unicode: 'ð' },
    { patterns: ['<3'], unicode: 'â¤ï¸' },
    { patterns: ['^.^'], unicode: 'ð' },
    { patterns: [':+1'], unicode: 'ð' },
    { patterns: [':-1'], unicode: 'ð' }
];

/*
 * Transforms common emoji text to UTF encoded emojis
*/
@Pipe({name: 'emojify'})
export class EmojifyPipe implements PipeTransform {
    transform(message: string, pipeEnabled: boolean): string {
        if (pipeEnabled && message && message.length > 1) {  
            emojiDictionary.forEach(emoji => {
                emoji.patterns.forEach(pattern => {
                    message = message.replace(pattern, emoji.unicode);
                })
            });
        }

    return message;
  }
}
